<template>
  <v-row v-if="product" class="mb-5 mb-sm-7 product-detail">
    <v-col cols="12" sm="6" class="py-0 py-sm-3">
      <v-card flat v-if="selectedImage">
        <!-- <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-img
              class="vendorIcon"
              v-bind="attrs"
              v-on="on"
              v-if="product.vendor.url"
              :src="product.vendor.url"
              height="auto"
              max-width="64"
              contain
              :alt="product.vendor.name"
            />
          </template>
          <span>{{ product.vendor.name }}</span>
        </v-tooltip> -->
        <ProductImages
          :images="product.media"
          :name="product.name"
          :key="product.productId"
        />
      </v-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      class="py-0 py-sm-3 default--text"
      style="position:relative"
    >
      <!-- :class="{ 'text-center': $vuetify.breakpoint.xs }" -->
      <h1 class="mb-1 product-name line-height-13">
        {{ product.name }}
      </h1>
      <div class="product-short-descr font-weight-normal">
        <div class="d-flex">
          <div class="text-h2 mb-1">
            {{ product.shortDescr }}
          </div>
          <span class="cod-int text-caption white--text"
            >cod. {{ product.codInt }}</span
          >
        </div>
        <span
          class="text-caption"
          v-if="
            product.productInfos.TIPOLOGIA == 'Pezzo' &&
              product.productInfos.PACKAGE_DESCR
          "
        >
          {{ product.productInfos.PACKAGE_DESCR }}
        </span>
      </div>
      <div class="text-h2 product-description font-weight-normal">
        {{ product.description }}
      </div>
      <span
        class="weight-unit"
        v-if="
          product.productInfos &&
            product.productInfos.TIPOLOGIA == 'Pezzo' &&
            product.priceUmDisplay
        "
      >
        {{ $n(product.priceUmDisplay, "currency") }}/{{
          product.weightUnitDisplay
        }}
      </span>
      <v-row
        no-gutters
        class="mb-4 mb-sm-0 mb-md-3 mt-3"
        justify="center"
        justify-sm="start"
      >
        <ProductClass
          :pClass="pClass"
          :width="34"
          :height="34"
          v-for="pClass in productClasses"
          :key="pClass.productClassId"
        />
      </v-row>

      <!-- <div class="promo-wrapper"> -->
      <!-- <ProductClass
          :pClass="pClass"
          v-for="pClass in promoProductClasses"
          :key="pClass.productClassid"
        /> -->
      <!-- <product-gift-certificate
          v-for="userGiftCertificate in product.userGiftCertificates"
          :key="userGiftCertificate.giftCertificate.giftCertificateId"
          :giftCertificate="userGiftCertificate.giftCertificate"
          @add="giftAdded"
        /> -->
      <!-- <ProductAcqLimit :product="product" class="mr-1" /> -->
      <!-- </div> -->
      <!-- <span
        v-if="product.warehousePromo && product.warehousePromo.view.bubble"
        class="promo-badge"
        v-html="product.warehousePromo.view.bubble"
      >
      </span> -->
      <div
        class="option-wrapper mt-3"
        v-for="selectOption in selectOptionsArray"
        :key="selectOption.name"
        @click.stop.prevent
        @mousedown.stop
      >
        <v-select
          outlined
          dense
          v-model="selectedOptions[selectOption.parameterName]"
          :items="selectOption.options"
          :label="selectOption.name"
          item-text="key"
          item-value="value"
          @change="optionChanged"
        />
      </div>

      <div v-if="sscProduct" class="item-sector mb-2">
        <div
          v-if="product.productInfos && product.productInfos.BLOCK_NAME"
          class="grey lighten-3 rounded"
        >
          <strong class="text-uppercase"
            >{{ $t("selfScanning.cartItem.department") }}:
          </strong>
          {{ product.productInfos.BLOCK_NAME }}
        </div>
        <div
          v-if="product.productInfos && product.productInfos.LANE"
          class="grey lighten-3 rounded"
        >
          <strong class="text-uppercase"
            >{{ $t("selfScanning.cartItem.lane") }}:
          </strong>
          <span>{{ product.productInfos.LANE }}</span>
          <span v-if="product.productInfos && product.productInfos.RACK">
            - {{ product.productInfos.RACK }}</span
          >
        </div>
      </div>

      <v-divider></v-divider>

      <div
        v-if="product.available > 0"
        class="d-flex align-center justify-space-between my-3 flex-sm-row"
        :style="{ 'min-height': product.warehousePromo ? '70px' : '' }"
      >
        <ProductPrice :product="product"></ProductPrice>

        <ProductQty
          v-if="!sscProduct"
          :selectedOptions="selectedOptions"
          :product="product"
          :item="item"
        />
      </div>
      <div
        v-else-if="product.available == 0"
        class="actions justify-center text-center text-caption error--text font-weight-bold"
      >
        {{ $t("product.notAvailable") }}
      </div>
      <v-divider></v-divider>

      <div class="d-flex flex-column">
        <div class="d-flex align-center" @click="addToFavorites">
          <v-btn icon large>
            <v-icon v-if="highlight" color="primary">$heartfull</v-icon>
            <v-icon v-else>$heart</v-icon>
          </v-btn>

          <span class="text-body-1 add-to-list-text">{{
            $t("product.addToList")
          }}</span>
        </div>
      </div>
      <v-divider></v-divider>
      <ProductRating
        class="mt-4"
        :product="product"
        v-if="isAuthenticated"
        @click="goToProductCustomerVote()"
      />
    </v-col>
  </v-row>
</template>
<style lang="scss">
.product-detail {
  .price {
    margin-bottom: 5px;
  }
  .actions {
    min-height: 58px;
  }

  .product-short-descr .text-h2,
  .product-description.text-h2 {
    line-height: 1.5;
    font-family: $body-font-family !important;
    font-weight: normal;
  }
  .promo-wrapper {
    //position: initial;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    .certificate-box {
      margin-right: 2px;
      max-width: 900px;
    }
  }
  .vendorIcon {
    position: absolute;
    right: 6px;
    top: -18px;
    z-index: 6;
  }

  .qty-wrap {
    width: 150px;
    .plus {
      width: 65px;
    }
    &.not-empty {
      .plus {
        width: 45px;
      }
    }
  }
  .promo-wrapper {
    max-height: 73px;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: auto;
    }
  }
  .old-price {
    font-size: 14px !important;
  }
  .cur-price {
    font-size: 36px;
  }
  .price-um {
    font-size: 12px !important;
    margin-top: 5px !important;
  }
  .add-to-list-text.text-body-2 {
    font-size: 16px !important;
  }
}
</style>
<script>
import ProductPrice from "@/components/product/ProductPrice.vue";
// import ProductPromo from "@/components/product/ProductPromo.vue";
import ProductClass from "@/components/product/ProductClass.vue";
import ProductImages from "@/components/product/ProductImages.vue";
import ProductQty from "./ProductQty.vue";
import ProductRating from "@/components/product/ProductRating.vue";
// import ProductCustomerVotes from "@/components/product/ProductCustomerVotes.vue";

import productMixin from "~/mixins/product";
import { mapState, mapGetters } from "vuex";

import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductDetail",
  props: ["selectedImage", "product"],
  components: {
    ProductPrice,
    // ProductPromo,
    ProductClass,
    ProductImages,
    // ProductGiftCertificate,
    ProductQty,
    ProductRating
    // ProductCustomerVotes
  },
  mixins: [productMixin],
  data() {
    return {
      imageDetailDialog: false,
      selectedOptions: {},
      selectOptionsArray: [],
      sscProduct: false
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    }
  },
  methods: {
    goToProductCustomerVote() {
      // this.$vuetify.goTo(this.$refs.disclaimerform);
      this.$emit("goToCustomerVote", true);
    },
    selectImage(media) {
      this.$emit("selectImage", media);
    },
    openImageDialog() {
      this.imageDetailDialog = true;
    },
    setOptionsArray() {
      if (this.product.selectOptions && this.product.selectOptions.length) {
        this.selectOptionsArray = cloneDeep(this.product.selectOptions);
      } else {
        this.selectOptionsArray = [];
      }
    }
  },
  created() {
    this.setOptionsArray();
    if (this.$route.name == "SscProduct") {
      this.sscProduct = true;
    }
  },
  watch: {
    product(val, oldVal) {
      if (val.productId !== oldVal.productId) {
        this.setOptionsArray();
      }
    }
  }
};
</script>
